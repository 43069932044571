/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap name={"q13ep9ji9wi"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"lgz2ao2y4fp"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" name={"aqhw8g3igyw"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"Prodejna"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"+420 601 389 141<br>&nbsp;klicezamkymolek@gmail.com\n<br>Na autobusovém nádraží v Novém Jičíně\n<br>Po: 7:30 - 12:00, 12:30 - 16:00<br>Út: 7:30 - 12:00, 12:30 - 16:00<br>St: 7:30 - 12:00, 12:30 - 16:00<br>Čt: 7:30 - 12:00, 12:30 - 16:00<br>Pá: 7:30 - 12:00, 12:30 - 16:00<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/f676559e8a404c098627344d714761f2_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/f676559e8a404c098627344d714761f2_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/f676559e8a404c098627344d714761f2_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/f676559e8a404c098627344d714761f2_s=860x_.png 860w, https://cdn.swbpg.com/t/19567/f676559e8a404c098627344d714761f2_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"n02fz00obj"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--36" content={"Montáže a nouzové otevírání"}>
              </Subtitle>

              <Text className="text-box" content={"Petr Molek <br>+420 775 479 613<br>klicezamkymolek@gmail.com<br>IČ: 08961271<br>DIČ: 9006085979<br>Dlouhá 798/19, 741 01 Nový Jičín<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit","align":"left"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}